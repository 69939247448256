import { skipToken, useQuery } from '@apollo/client';
// It is safe to use this package here, because it is available in the new
// consumer app, and in the old package a mock is used.
// eslint-disable-next-line import/no-unresolved
import { useSuspenseQuery as useSuspenseQueryOriginal } from '@apollo/experimental-nextjs-app-support/ssr';

import isNewConsumerApp from './isNewConsumerApp';

const useSuspenseQueryNew = (query, originalOptions) => {
  // If skip is truthy, pass skipToken instead of options in the new app to skip
  // the query when using useSuspenseQuery
  const options = originalOptions?.skip ? skipToken : originalOptions;

  return useSuspenseQueryOriginal(query, options);
};

// The old consumer app is unable to use React 18 mode which is required for
// server-side suspense, so useSuspenseQuery from Apollo is only used in the
// new app.

// This hook is used wherever we need to load API data from Apollo Client
const useSuspenseQuery = isNewConsumerApp ? useSuspenseQueryNew : useQuery;

export default useSuspenseQuery;
