import { GET_ACCOUNT_INFO } from 'mocks/queries';
import { identifyFBUser } from 'commons/fbEvents';
import useIsBrowser from 'hooks/useIsBrowser';
import useUserLocation from 'global-state/userLocation/useUserLocation';
import useQuery from 'commons/useQuery';
import { isNil, omitBy } from 'lodash';

const useIdentifyFacebookUser = () => {
  const isBrowser = useIsBrowser();
  const userLocation = useUserLocation();
  const userAccountInfo = useQuery(GET_ACCOUNT_INFO, {
    fetchPolicy: 'network-only',
  });

  const identifyFacebookUser = ({ email, firstName, lastName, userId }) => {
    // Only run in the browser
    if (!isBrowser) {
      return;
    }
    const userDetails = omitBy(
      {
        em: email,
        fn: firstName,
        ln: lastName,
        external_id: userId,
        ct: userLocation.city,
        st: userLocation.stateShortName,
        zp: userLocation.zip,
        country: userLocation.country,
        ph: userAccountInfo?.phone || userAccountInfo?.phone2,
      },
      isNil
    );

    identifyFBUser(userDetails);
  };
  return identifyFacebookUser;
};

export default useIdentifyFacebookUser;
