import { useMemo } from 'react';

import {
  COMPONENT_CALLER_TYPE,
  FABRIC_PRODUCT_OPTION_ID,
  PDP_DECIDELATER_TAB_ID,
} from 'commons/constants';
import {
  getDefaultOptionData,
  getAdditionalCostForSKU,
  getExcludeFromSaleForSKU,
} from 'commons/productUtils';
import usePdpSelectedCustomizerTab from 'components/consumer/PDP/state/pdpSelectedCustomizerTab/usePdpSelectedCustomizerTab';
import usePDPBasicDetails from 'components/consumer/PDP/state/context/usePDPBasicDetails';
import usePDPOptions from 'components/consumer/PDP/state/context/usePDPOptions';
import useUserData from '../useUserData';
import useProductBasicDetails from '../useProductBasicDetails/index';
import useProductCardOptions from '../useProductCardOptions';
import useClearanceData from '../useClearanceData';
import useProductCardSwatches from '../useProductCardSwatches';

import {
  getOptionAdditionalPrice,
  getForMaterialAdditionalPrice,
  getForMaterialExcludeFromSale,
  getFirstStockOptionPrice,
  getFirstStockOptionExcludeFromSale,
} from './utils';

const useProductPrice = (id, slug, hookOptions = {}) => {
  const {
    callerType = COMPONENT_CALLER_TYPE.PDP,
    delayLoad = false,
    forMaterial,
    forMaterialType,
    forVariant,
    layout,
    localStockHookOptions,
    productSku,
    skipLocalStockHook = false,
    regionId,
  } = hookOptions;

  const { isTradeUser, userDiscount } = useUserData();
  const pdpSelectedCustomizerTab = usePdpSelectedCustomizerTab();
  const isDecideLaterTab = pdpSelectedCustomizerTab === PDP_DECIDELATER_TAB_ID;

  const isProductCardCaller = callerType === COMPONENT_CALLER_TYPE.PRODUCT_CARD;

  const nonPDPBasicDetails = useProductBasicDetails(id, slug, {
    skipPDPStateHook: isProductCardCaller,
    productSku,
    delayLoad,
    regionId,
  });

  const pdpBasicDetails = usePDPBasicDetails();
  const pdpOptions = usePDPOptions();

  const basicLoading = isProductCardCaller
    ? nonPDPBasicDetails?.loading
    : pdpBasicDetails?.loading;

  const basicError = isProductCardCaller
    ? nonPDPBasicDetails?.error
    : pdpBasicDetails?.error;

  const basicData = isProductCardCaller
    ? nonPDPBasicDetails?.data
    : pdpBasicDetails?.data;

  const productCardOptions = useProductCardOptions(
    id,
    slug,
    { skipLocalStockHook, localStockHookOptions, productSku, delayLoad },
    { skip: !isProductCardCaller }
  );

  const { loading: optionsLoading, error: optionsError, data: optionsData } =
    (isProductCardCaller ? productCardOptions : pdpOptions) ?? {};

  const productSwatchData = useProductCardSwatches(
    id,
    null,
    {
      skipLocalStockHook,
      skipPDPStateHook: callerType === COMPONENT_CALLER_TYPE.PRODUCT_CARD,
      localStockHookOptions,
      productSku,
      delayLoad,
    },
    {
      skipHook:
        !(callerType === COMPONENT_CALLER_TYPE.PRODUCT_CARD) ||
        layout !== 'category',
    }
  );
  const clearanceHookData = useClearanceData(id, slug, {
    ...hookOptions,
    callerType,
  });

  const {
    isClearance = false,
    stockCost = 0,
    clearanceStockPerSKU,
    productCardVariantSKU,
    clearanceProductSku,
  } = clearanceHookData?.data || {};
  const { selectedSwatchIndex = 0 } = productSwatchData || {};
  const { options = [], selectedOptions = {}, stock } = optionsData || {};

  const isFabricExcludedFromSale = useMemo(() => {
    if (callerType === COMPONENT_CALLER_TYPE.PRODUCT_CARD) {
      if (
        (!forMaterial &&
          layout === 'category' &&
          !productSwatchData?.fabricSwatches?.[selectedSwatchIndex]
            ?.fabricAdditionalCost) ||
        !options?.length
      ) {
        return false;
      }

      if (forMaterial && forMaterialType) {
        return getForMaterialExcludeFromSale({
          options,
          forMaterial,
          forMaterialType,
          id,
        });
      }

      if (forVariant) {
        return getExcludeFromSaleForSKU(forVariant, options);
      }

      if (layout !== 'category') {
        // If EFG Internal check stock
        if (
          basicData?.is_efg &&
          !basicData?.is_external &&
          stock?.stockOptions?.length
        ) {
          return getFirstStockOptionExcludeFromSale(stock.stockOptions);
        }

        const defaultOption = getDefaultOptionData(options);
        return defaultOption.optionData?.exclude_from_sale || false;
      }
    }

    const selectedFabricOptionValueId =
      selectedOptions?.[FABRIC_PRODUCT_OPTION_ID];
    if (selectedFabricOptionValueId) {
      const fabricOption = options?.length
        ? options.find(o => o.id === FABRIC_PRODUCT_OPTION_ID)
        : null;
      if (fabricOption?.values?.length > 0) {
        const fabricOptionValue = fabricOption.values.find(
          o => o.id === selectedFabricOptionValueId
        );
        return fabricOptionValue?.exclude_from_sale ?? false;
      }
    }
    return false;
  }, [
    id,
    layout,
    options,
    callerType,
    selectedOptions,
    forVariant,
    forMaterial,
    forMaterialType,
    basicData?.is_efg,
    basicData?.is_external,
    stock?.stockOptions,
    selectedSwatchIndex,
    productSwatchData?.fabricSwatches,
  ]);

  const priceObj = useMemo(() => {
    if (!basicData?.meta?.price) {
      return {};
    }
    let finalPrice = basicData.meta.price;
    const originalPrice = basicData?.meta?.price?.original_price || 0;

    if (isClearance && !isDecideLaterTab) {
      finalPrice = {
        ...finalPrice,
        final_price: originalPrice + stockCost,
      };
    } else if (isFabricExcludedFromSale && !isDecideLaterTab) {
      finalPrice = {
        ...finalPrice,
        discount_percent: isTradeUser ? userDiscount : 0,
        max_discount_percent: isTradeUser ? userDiscount : 0,
        final_price: isTradeUser
          ? Math.round((originalPrice * (100 - userDiscount)) / 100)
          : originalPrice,
      };
    }

    return finalPrice;
  }, [
    basicData,
    isClearance,
    stockCost,
    isDecideLaterTab,
    isFabricExcludedFromSale,
    isTradeUser,
    userDiscount,
  ]);

  const optionPrice = useMemo(() => {
    if (callerType === COMPONENT_CALLER_TYPE.PRODUCT_CARD) {
      return 0;
    }

    return getOptionAdditionalPrice(
      options,
      stock?.stockOptions,
      selectedOptions,
      !isDecideLaterTab,
      optionsData?.upholsteryOptionId
    );
  }, [
    callerType,
    options,
    stock?.stockOptions,
    selectedOptions,
    isDecideLaterTab,
    optionsData?.upholsteryOptionId,
  ]);

  const productCardOptionPrice = useMemo(() => {
    if (
      (!forMaterial &&
        layout === 'category' &&
        !productSwatchData?.fabricSwatches?.[selectedSwatchIndex]
          ?.fabricAdditionalCost) ||
      !optionsData?.options?.length ||
      callerType !== COMPONENT_CALLER_TYPE.PRODUCT_CARD
    ) {
      return 0;
    }

    if (forMaterial && forMaterialType) {
      return getForMaterialAdditionalPrice({
        options,
        forMaterial,
        forMaterialType,
        id,
      });
    }

    if (forVariant) {
      return getAdditionalCostForSKU(forVariant, options);
    }

    // Get first / default option price
    if (layout !== 'category') {
      // If EFG Internal check stock
      if (
        basicData?.is_efg &&
        !basicData?.is_external &&
        optionsData.stock?.stockOptions?.length
      ) {
        return getFirstStockOptionPrice(optionsData.stock.stockOptions);
      }

      const defaultOption = getDefaultOptionData(optionsData?.options);
      if (defaultOption?.optionData) {
        return defaultOption.optionData.additional_cost;
      }

      return 0;
    }

    return productSwatchData?.fabricSwatches?.[selectedSwatchIndex]
      ?.fabricAdditionalCost;
  }, [
    basicData,
    callerType,
    forMaterial,
    forMaterialType,
    forVariant,
    id,
    layout,
    options,
    optionsData,
    productSwatchData?.fabricSwatches,
    selectedSwatchIndex,
  ]);

  const promotion = isClearance ? {} : basicData?.meta?.promotion;

  return {
    loading: optionsLoading || basicLoading,
    error: optionsError || basicError,
    data: basicData
      ? {
          price: priceObj,
          promotion,
          optionPrice,
          isClearance,
          productCardOptionPrice,
          optionValuesCount: optionsData?.optionValuesCount || 0, // Only used by product cards
          stockCost,
          clearanceStockPerSKU,
          productCardVariantSKU,
          clearanceProductSku,
        }
      : null,
  };
};

export default useProductPrice;
