import { useMemo } from 'react';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import useAllOptions from '../useAllOptions';
import useProductLocalStock from '../useProductLocalStock';
import { PRODUCT_OPTIONS } from '../queries';
import {
  isUpholsteryOption,
  convertProductOptionsToUsableFormat,
} from '../../components/consumer/PDP/helpers/pdpOptionUtils';

const useProductCardOptions = (id, slug, queryOptions = {}, skipHook = {}) => {
  const { skip: skipRun = false, defaultValue = {} } = skipHook;

  const {
    skipStockRequest = true,
    skipLocalStockHook = true,
    ssr = true,
    localStockHookOptions = {},
    delayLoad = false,
    productSku = '',
  } = queryOptions;
  const finalProductQueryVariables = id
    ? { id }
    : productSku
    ? { productSku }
    : { slug };
  const {
    loading: allOptionValuesLoading,
    allOptionsValues,
    error: allOptionValuesError,
  } = useAllOptions();

  const {
    loading: productOptionsLoading,
    error: productOptionsError,
    data: productOptionsData,
  } = useSuspenseQuery(PRODUCT_OPTIONS, {
    variables: { ...finalProductQueryVariables },
    skip: (!id && !productSku && !slug) || delayLoad,
    context: { noBatch: true, ssr },
  });

  const productOptionsNonStock = useMemo(() => {
    if (!productOptionsData?.productOptions || !allOptionsValues) {
      return null;
    }
    const finalOptions = convertProductOptionsToUsableFormat(
      productOptionsData,
      allOptionsValues
    );
    return finalOptions;
  }, [productOptionsData, allOptionsValues]);

  const optionValuesCount = useMemo(() => {
    if (!productOptionsNonStock?.length) {
      return 0;
    }
    const upholsteryOption = productOptionsNonStock.find(option =>
      isUpholsteryOption(option.id)
    );
    if (!upholsteryOption) {
      return 0;
    }
    return upholsteryOption.values.length;
  }, [productOptionsNonStock]);

  let stock = {};
  if (!skipLocalStockHook) {
    // TODO: add skip option to this hook
    // eslint-disable-next-line react-hooks/rules-of-hooks
    stock = useProductLocalStock({
      slug,
      id: productOptionsData?.productOptions?.id,
      skipStockRequest,
      hookOptions: localStockHookOptions,
      options: productOptionsNonStock,
      skipPDPState: true,
    });
  }

  const upholsteryOptionId =
    productOptionsNonStock &&
    productOptionsNonStock
      .map(({ id: optionId }) => optionId)
      .find(isUpholsteryOption);

  if (skipRun) {
    return defaultValue === {} ? { loading: true } : defaultValue;
  }

  return {
    loading:
      allOptionValuesLoading || productOptionsLoading || (!id && !productSku),
    error: allOptionValuesError || productOptionsError,
    data: {
      options: productOptionsNonStock,
      upholsteryOptionId,
      stock,
      optionValuesCount,
    },
  };
};

export default useProductCardOptions;
