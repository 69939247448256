/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
// We hit a number of issues while trying to fix the ESLint issues in this
// file. Some changes that were attempted caused infinite loops in SSR mode,
// and some caused unit test failures. Instead, we have temporarily disabled
// these two rules but we should investigate these issues again after migrating
// to Next.js (when it will be easier to run SSR mode locally) and try to
// refactor this code to improve maintainability.
import { useMemo, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import ProductCardVar from 'link-state/product-card/productCardVars';
import isServer from 'commons/isServer';
import { GET_FABRIC_DATA } from 'mocks/queries';
import useProductCardContext from 'data-hooks/useProductCardContext';
import useProductCardOptions from '../useProductCardOptions';
import useProductBasicDetails from '../useProductBasicDetails';

import {
  sortOptionsByColor,
  getFabricSwatchesForColors,
  setVelvetMaterials,
  getFilteredVelvetSwatches,
  shouldCurrentSwatchSetUpdate,
  getTopFabricSwatchesData,
  getQuickShipSwatchDataFromOptions,
} from './utils';

const useProductCardSwatches = (
  id,
  slug = null,
  queryOptions = {},
  hookOptions = {},
  displaySwatchCount = 4
) => {
  const {
    skipStockRequest = true,
    skipLocalStockHook = true,
    localStockHookOptions,
    position,
    noBatch = false,
    productSku = '',
    delayLoad = false,
  } = queryOptions;

  const {
    enableWriteToCache = false,
    forMaterialGroupOptionIds = {},
    showVariation = '',
    skipHook = false,
    currentParentColors,
    forMaterialGroup,
    selectedDynFilters,
    forMaterial,
    forMaterialType,
  } = hookOptions;

  const productCardContextData = useProductCardContext();

  if (skipHook || !!productCardContextData) {
    return { actions: {} };
  }

  const [selectedSwatchIndexValue, setSelectedSwatchIndex] = useState(0);
  const [currentSwatchFilters, updateCurrentSwatchFilters] = useState({
    popular: false,
    color: false,
    velvet: false,
    quickship: false,
    quickshipEFG: false,
  });
  const [popularSwatches, setPopularSwatches] = useState(null);
  const [quickShipSwatches, setQuickShipSwatches] = useState(null);
  const [allSwatchesCount, setAllSwatchesCount] = useState(0);
  const [noFiltersMatched, setNoFiltersMatched] = useState(false);

  const productCardVar = isServer()
    ? null
    : ProductCardVar.getProductCardVar(id || productSku);

  const productCardReactiveState = isServer()
    ? {}
    : useReactiveVar(productCardVar);

  const {
    data: allUpholsteryOptions,
    loading: allUpholsteryOptionsLoading,
  } = useSuspenseQuery(GET_FABRIC_DATA, {
    context: { noBatch },
    skip: delayLoad || !enableWriteToCache,
  });

  const {
    loading: optionsDataLoading,
    data: optionsData,
  } = useProductCardOptions(id, slug, {
    skipStockRequest,
    skipLocalStockHook,
    localStockHookOptions,
    noBatch,
    productSku,
    delayLoad: delayLoad || !enableWriteToCache,
  });

  const {
    loading: basicDetailsLoading,
    data: basicDetailsData,
  } = useProductBasicDetails(id, slug, {
    skipPDPStateHook: true,
    productSku,
    delayLoad: delayLoad || !enableWriteToCache,
  });

  const isEFGInternal =
    basicDetailsData?.efg_flag && !basicDetailsData?.is_external;
  const isQuickShipOn =
    !!currentSwatchFilters.quickship || !!selectedDynFilters?.quickship;

  useEffect(() => {
    if (
      !optionsData?.options?.length ||
      !basicDetailsData?.id ||
      popularSwatches?.length ||
      !enableWriteToCache
    ) {
      return;
    }
    const { options } = optionsData || {};

    const [popSwatches, swatchesCount] = getTopFabricSwatchesData({
      product: basicDetailsData,
      options,
      showVariation,
      forMaterial,
      forMaterialType,
      displaySwatchCount,
    });

    setPopularSwatches(popSwatches);
    setAllSwatchesCount(swatchesCount);
  }, [optionsData?.options, basicDetailsData?.id, showVariation]);

  useEffect(() => {
    if (
      !optionsData?.stock?.stockOptions?.length ||
      (basicDetailsData?.id === id && quickShipSwatches?.length) ||
      !enableWriteToCache
    ) {
      return;
    }
    const quickShipSwatchValues = getQuickShipSwatchDataFromOptions({
      stockOptions: optionsData.stock.stockOptions,
      product: basicDetailsData,
    });

    const finalQSSwatches =
      quickShipSwatchValues?.length > displaySwatchCount
        ? quickShipSwatchValues.slice(0, displaySwatchCount)
        : quickShipSwatchValues;
    setQuickShipSwatches(finalQSSwatches);
  }, [optionsData?.stock?.stockOptions?.[0]?.values]);

  const sortedAllOptionsValues = useMemo(() => {
    if (!allUpholsteryOptions?.getFabrics || !enableWriteToCache) {
      return null;
    }
    return sortOptionsByColor(allUpholsteryOptions.getFabrics);
  }, [allUpholsteryOptions?.getFabrics]);

  const allVelvetMaterialOptions = useMemo(() => {
    if (isEmpty(forMaterialGroupOptionIds) || !sortedAllOptionsValues) {
      return null;
    }
    return setVelvetMaterials({
      forMaterialGroupOptionIds,
      sortedAllOptionsValues,
    });
  }, [forMaterialGroupOptionIds, sortedAllOptionsValues]);

  const updateProductVarSwatches = (updatedSwatches, updatedSwatchIndex) => {
    if (
      !enableWriteToCache ||
      !shouldCurrentSwatchSetUpdate({
        currentSet: productCardReactiveState?.swatches,
        newSet: updatedSwatches,
      })
    ) {
      return;
    }

    productCardVar({
      ...productCardVar(),
      swatches: [...updatedSwatches],
      swatchIndex: updatedSwatchIndex,
    });
  };

  useEffect(() => {
    if (
      !basicDetailsData?.id ||
      !enableWriteToCache ||
      productCardReactiveState?.swatchIndex === selectedSwatchIndexValue
    ) {
      return;
    }
    productCardVar({
      ...productCardVar(),
      swatchIndex: selectedSwatchIndexValue,
    });
  }, [selectedSwatchIndexValue, basicDetailsData?.id]);

  // Set current swatches to popular when no filter applied
  useEffect(() => {
    if (!enableWriteToCache) {
      return;
    }
    if (
      !currentSwatchFilters.color &&
      !currentSwatchFilters.velvet &&
      !currentSwatchFilters.quickship &&
      !currentSwatchFilters.quickshipEFG &&
      !currentSwatchFilters.popular &&
      popularSwatches?.length
    ) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        popular: true,
      });
      updateProductVarSwatches(popularSwatches);
    }
  }, [popularSwatches, currentSwatchFilters]);

  // Set current swatches to quickship if EFG Internal
  useEffect(() => {
    if (
      !basicDetailsData ||
      !quickShipSwatches?.length ||
      !basicDetailsData.efg_flag ||
      !!basicDetailsData.is_external ||
      !enableWriteToCache
    ) {
      return;
    }
    updateCurrentSwatchFilters({
      ...currentSwatchFilters,
      popular: false,
      quickship: true,
      quickshipEFG: true,
    });
    updateProductVarSwatches(quickShipSwatches);
  }, [basicDetailsData?.id, quickShipSwatches]);

  useEffect(() => {
    if (
      !quickShipSwatches?.length ||
      (currentSwatchFilters.quickship &&
        productCardReactiveState?.swatches?.[0]?.fabricId ===
          quickShipSwatches?.[0]?.fabricId) ||
      !currentSwatchFilters.quickship ||
      !enableWriteToCache
    ) {
      return;
    }
    updateProductVarSwatches(quickShipSwatches, 0);
  }, [quickShipSwatches]);

  // Methods only called for hook with enableWriteToCache
  const filterSwatchesByColor = colorsToFilterBy => {
    if (
      !sortedAllOptionsValues ||
      !optionsData?.options ||
      !enableWriteToCache
    ) {
      return;
    }
    if (colorsToFilterBy.length && !currentSwatchFilters.color) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        popular: false,
        color: true,
      });
    }
    if (!colorsToFilterBy.length && currentSwatchFilters.color) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        color: false,
      });
    }
    const finalOptions = isQuickShipOn
      ? optionsData.stock.stockOptions
      : optionsData.options;

    const [
      updatedFilteredSwatches,
      updatedSwatchIndex,
    ] = getFabricSwatchesForColors({
      sortedAllOptionsValues,
      options: finalOptions,
      product: basicDetailsData,
      filteredColors: colorsToFilterBy,
      position,
    });

    updateProductVarSwatches(updatedFilteredSwatches, updatedSwatchIndex);
    if (
      !updatedFilteredSwatches?.length &&
      optionsData.options.length &&
      colorsToFilterBy.length &&
      isQuickShipOn &&
      Array.isArray(optionsData.stock.stockOptions)
    ) {
      setNoFiltersMatched(true);
    }
  };

  const filterSwatchesByVelvet = (velvetFilterStatus, colorsToFilterBy) => {
    if (
      isEmpty(allVelvetMaterialOptions) ||
      !optionsData?.options ||
      (!!isEFGInternal &&
        !optionsData?.stock?.stockOptions &&
        !optionsData?.stock?.selectedOptionsStockQty) ||
      (!velvetFilterStatus && !currentSwatchFilters.velvet) ||
      !enableWriteToCache
    ) {
      return;
    }
    if (!velvetFilterStatus && currentSwatchFilters.velvet) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        velvet: false,
      });
      return;
    }

    if (!currentSwatchFilters.velvet) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        popular: false,
        velvet: true,
      });
    }

    const finalOptions =
      isEFGInternal || !!selectedDynFilters.quickship
        ? optionsData.stock.stockOptions
        : optionsData.options;
    const [
      updatedFilteredSwatches,
      updatedSwatchIndex,
    ] = getFilteredVelvetSwatches({
      product: basicDetailsData,
      filteredColors: colorsToFilterBy,
      options: finalOptions,
      allVelvetMaterialOptions,
      position,
    });

    updateProductVarSwatches(updatedFilteredSwatches, updatedSwatchIndex);
    if (
      !updatedFilteredSwatches?.length &&
      optionsData.options.length &&
      velvetFilterStatus &&
      isQuickShipOn &&
      !isEmpty(allVelvetMaterialOptions) &&
      Array.isArray(optionsData.stock.stockOptions)
    ) {
      setNoFiltersMatched(true);
    }
  };

  useEffect(() => {
    if (
      !currentSwatchFilters?.color ||
      !sortedAllOptionsValues ||
      !optionsData?.options
    ) {
      return;
    }
    filterSwatchesByColor(currentParentColors);
    // Adding dependencies to array breaks unit tests
    // more complex restructuring is likely needed - let's revisit in the near
    // future.
  }, [sortedAllOptionsValues]);

  useEffect(() => {
    if (
      forMaterialGroup !== 'velvet' ||
      isEmpty(allVelvetMaterialOptions) ||
      !optionsData?.options
    ) {
      return;
    }
    filterSwatchesByVelvet(true, currentParentColors);
  }, [allVelvetMaterialOptions]);

  // Trigger color or velvet filtering if not done already
  useEffect(() => {
    if (
      !selectedDynFilters?.quickship ||
      !optionsData?.stock?.stockOptions?.length ||
      !enableWriteToCache
    ) {
      return;
    }
    if (selectedDynFilters?.color?.length && !currentSwatchFilters.color) {
      filterSwatchesByColor(selectedDynFilters.color);
    }
    if (
      selectedDynFilters?.material?.includes('velvet') &&
      !currentSwatchFilters.velvet
    ) {
      filterSwatchesByVelvet(true, selectedDynFilters?.color);
    }
  }, [optionsData.stock.stockOptions]);

  const filterSwatchesByQuickShip = (
    quickShipFilterStatus,
    colorsToFilterBy,
    isVelvetFilterOn
  ) => {
    if (!enableWriteToCache) {
      return;
    }

    if (quickShipFilterStatus && !currentSwatchFilters.quickship) {
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        popular: false,
        quickship: true,
      });
      if (quickShipSwatches?.length) {
        updateProductVarSwatches(quickShipSwatches, 0);
      }
    } else if (!quickShipFilterStatus && currentSwatchFilters.quickship) {
      if (isVelvetFilterOn && currentSwatchFilters.velvet) {
        filterSwatchesByVelvet(true, colorsToFilterBy);
      } else if (
        !isVelvetFilterOn &&
        colorsToFilterBy?.length &&
        currentSwatchFilters.color
      ) {
        filterSwatchesByColor(colorsToFilterBy);
      }
      updateCurrentSwatchFilters({
        ...currentSwatchFilters,
        quickship: false,
      });
    }
  };

  const setSwatchHovered = (forProductId, swatchHoveredState) => {
    if (forProductId === id) {
      productCardVar({
        ...productCardVar(),
        swatchHovered: swatchHoveredState,
      });
    }
  };

  const finalSwatchesCount =
    productCardReactiveState?.swatches?.length && allSwatchesCount
      ? allSwatchesCount - (productCardReactiveState?.swatches?.length ?? 0) > 0
        ? allSwatchesCount - (productCardReactiveState?.swatches?.length ?? 0)
        : 0
      : 0;

  return {
    loading:
      basicDetailsLoading || optionsDataLoading || allUpholsteryOptionsLoading,
    fabricSwatches: productCardReactiveState?.swatches?.length
      ? productCardReactiveState.swatches
      : isServer() && enableWriteToCache
      ? popularSwatches
      : [], // Returning popular swatches on the server to allow faster render
    selectedSwatchIndex: productCardReactiveState?.swatchIndex || 0,
    swatchHovered: !!productCardReactiveState?.swatchHovered,
    allSwatchesCount: finalSwatchesCount,
    currentSwatchFilters,
    noFiltersMatched,
    actions: {
      setSelectedSwatchIndex,
      filterSwatchesByColor,
      filterSwatchesByVelvet,
      filterSwatchesByQuickShip,
      setProductCardSwatchHovered: setSwatchHovered,
    },
  };
};

export default useProductCardSwatches;
