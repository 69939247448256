import { useMemo, useEffect, useState } from 'react';

import { isEmpty, isEqual } from 'lodash';
import { getDefaultOptionData } from 'commons/productUtils';
import { COMPONENT_CALLER_TYPE } from 'commons/constants';
import isServer from 'commons/isServer';
import useProductCardSwatches from '../useProductCardSwatches';
import useProductCardOptions from '../useProductCardOptions';
import useAllProductClearanceStock from '../useAllProductClearanceStock';
import useProductBasicDetails from '../useProductBasicDetails/index';

import {
  getForMaterialSKU,
  getFirstStockOptionSKU,
} from '../useProductPrice/utils';

const useProductCardClearanceData = (
  id = null,
  slug = null,
  hookOptions = {}
) => {
  const [data, setData] = useState({
    isClearance: false,
    clearanceStockPerSKU: {},
    stockCost: 0,
    productCardVariantSKU: null,
    clearanceProductSku: [],
  });
  const {
    callerType = COMPONENT_CALLER_TYPE.PRODUCT_CARD,
    layout,
    forMaterial,
    forMaterialType,
    skipLocalStockHook,
    localStockHookOptions,
    productSku,
    delayLoad = false,
    forVariant = '',
    skip = false,
    defaultValue = {},
  } = hookOptions;

  const { error: optionsError, data: optionsData } = useProductCardOptions(
    id,
    slug,
    {
      skipLocalStockHook: true,
      localStockHookOptions,
      productSku,
      delayLoad: isServer() || delayLoad,
    }
  );

  const { options = [] } = optionsData || {};

  const {
    // loading: clearanceLoading,
    data: clearanceData,
  } = useAllProductClearanceStock({ callerType });

  const {
    loading: basicLoading,
    error: basicError,
    data: basicData,
  } = useProductBasicDetails(id, slug, {
    skipPDPStateHook: true,
    delayLoad: isServer() || delayLoad,
  });

  const productSwatchData = useProductCardSwatches(
    id,
    null,
    {
      skipLocalStockHook,
      skipPDPStateHook: true,
      localStockHookOptions,
      productSku,
      delayLoad: isServer() || delayLoad,
    },
    {
      skipHook: layout !== 'category',
    }
  );

  const { selectedSwatchIndex } = productSwatchData || {};

  const productCardVariantSKU = useMemo(() => {
    if (
      isEmpty(basicData) ||
      (!forMaterial &&
        layout === 'category' &&
        isEmpty(productSwatchData?.fabricSwatches)) ||
      !optionsData?.options?.length ||
      callerType !== COMPONENT_CALLER_TYPE.PRODUCT_CARD ||
      isServer()
    ) {
      return '';
    }

    if (forMaterial && forMaterialType) {
      return getForMaterialSKU({
        options,
        forMaterial,
        forMaterialType,
        id,
      });
    }

    // Get first / default option price
    if (layout !== 'category') {
      if (forVariant) {
        return forVariant;
      }
      // If EFG Internal check stock
      if (
        basicData?.is_efg &&
        !basicData?.is_external &&
        optionsData.stock?.stockOptions?.length
      ) {
        return getFirstStockOptionSKU(optionsData.stock.stockOptions);
      }
      const defaultOption = getDefaultOptionData(optionsData?.options);
      if (defaultOption?.optionData) {
        return defaultOption.optionData.sku;
      }

      return '';
    }

    return productSwatchData?.fabricSwatches?.[selectedSwatchIndex]?.fabricSku;
    // TODO: Refactor and investigate this effect - all dependencies should be
    // added and confirmed as working after we have upgraded to Next.js (when
    // it is easier to develop and test server rendering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optionsData,
    productSwatchData?.fabricSwatches,
    productSwatchData?.selectedSwatchIndex,
    productSwatchData?.swatchHovered,
    forMaterial,
    forMaterialType,
    basicData,
    forVariant,
  ]);

  useEffect(() => {
    if (
      callerType !== COMPONENT_CALLER_TYPE.PRODUCT_CARD ||
      basicLoading ||
      isEmpty(clearanceData)
    ) {
      return;
    }

    let foundClearanceStock = false;
    const clearanceDataForProduct = basicData?.id
      ? clearanceData[basicData.id] || {}
      : {};

    if (clearanceDataForProduct) {
      Object.keys(clearanceDataForProduct).forEach(stockSku => {
        if (!foundClearanceStock && stockSku.includes(productCardVariantSKU)) {
          foundClearanceStock = clearanceDataForProduct[stockSku];
        }
      });
    }

    if (foundClearanceStock) {
      const stockCost = parseFloat(foundClearanceStock.stock_cost) || 0;
      const productDetails = {
        isClearance: stockCost < 0,
        clearanceStockPerSKU: foundClearanceStock || {},
        stockCost,
        productCardVariantSKU,
        clearanceProductSku: Object.keys(clearanceDataForProduct),
      };
      if (!isEqual(data, productDetails)) {
        setData(productDetails);
      }
    }
    // TODO: Refactor and investigate this effect - all dependencies should be
    // added and confirmed as working after we have upgraded to Next.js (when
    // it is easier to develop and test server rendering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCardVariantSKU, basicData, clearanceData]);

  if (skip) {
    return defaultValue;
  }

  return {
    error: optionsError || basicError,
    data,
  };
};

export default useProductCardClearanceData;
