import React from 'react';
import { useApolloClient } from '@apollo/client';

const withApolloClient = ComponentToWrap => {
  const WithApolloClient = props => {
    const client = useApolloClient();
    return <ComponentToWrap {...props} client={client} />;
  };

  WithApolloClient.displayName = `withApolloClient(${ComponentToWrap.displayName ||
    ComponentToWrap.name})`;

  return WithApolloClient;
};

export default withApolloClient;
