import { useQuery as useQueryStandard } from '@apollo/client';
// It is safe to use this package here, because it is available in the new
// consumer app, and in the old package a mock is used.
// eslint-disable-next-line import/no-unresolved
import { useQuery as useQueryNew } from '@apollo/experimental-nextjs-app-support/ssr';

import isNewConsumerApp from './isNewConsumerApp';

// In the new consumer app, we need to use the useQuery hook that has been
// patched by @apollo/experimental-nextjs-app-support to support streaming SSR.

// This hook is used to load client-side-only data from Apollo Client
const useQuery = isNewConsumerApp ? useQueryNew : useQueryStandard;

export default useQuery;
