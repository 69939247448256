import { find } from 'lodash';
import {
  constructFabricUrl,
  getProductPrimaryOptionId,
  getFirstNOptionValues,
  sortOptionInPlaceByPopularOrder,
  findOptionAndTypeFromSkuV2,
  getOptionValueByNameAndOptionType,
} from 'commons/productUtils';
import {
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  WOOD_PRODUCT_OPTION_ID,
  VENDOR_LEATHER_PRODUCT_OPTION_ID,
} from 'commons/constants';
import convertOptionParamFormat from 'commons/convertOptionParamFormat';
import { logError } from 'commons/logger';

let ALLOPTIONS_CACHE = null;

export function getFabricOptionValueByPartSku({
  showVariation,
  options,
  primaryOptionTypeId,
}) {
  if (!options?.length || !showVariation) {
    return;
  }
  let finalVariation = showVariation;
  // For part skus
  if (!finalVariation.startsWith('-')) {
    finalVariation = `-${finalVariation}`;
  }
  const partSkus = finalVariation.split('-').filter(v => !!v);
  let upholsteryOptionValue = null;
  partSkus.forEach(partSku => {
    const [finalOptionValue, optionTypeId] = findOptionAndTypeFromSkuV2({
      options,
      partSku,
    });
    if (
      [
        FABRIC_PRODUCT_OPTION_ID,
        LEATHER_PRODUCT_OPTION_ID,
        WOOD_PRODUCT_OPTION_ID,
      ].includes(primaryOptionTypeId) &&
      optionTypeId === primaryOptionTypeId
    ) {
      upholsteryOptionValue = finalOptionValue;
    }
    return upholsteryOptionValue;
  });
}

function getPopularValuesFromOptions({ productOption, isEFG }) {
  sortOptionInPlaceByPopularOrder(productOption);
  let finalPopularValues = productOption.values.filter(
    optionValue => !!optionValue.popular
  );

  if (isEFG) {
    finalPopularValues = [...productOption.values];
  }

  return finalPopularValues;
}

export function fabricFallbackURL(imageName) {
  return `https://joybird2.imgix.net/option_images/swatches/${imageName}`;
}

function getFabricSwatchData({
  optionValueName,
  optionValueId,
  optionValueCost,
  optionTypeId,
  finalProductOption,
  optionSku,
  optionColor,
}) {
  const optionValueForImgFallback = find(finalProductOption.values, {
    id: optionValueId,
  });
  const optionsImage = optionValueForImgFallback
    ? optionTypeId === WOOD_PRODUCT_OPTION_ID
      ? optionValueForImgFallback.sprite_config
      : fabricFallbackURL(optionValueForImgFallback.image)
    : '';
  const urlString = optionValueName
    .toLowerCase()
    .split(' ')
    .join('-');
  return {
    fabricImage:
      constructFabricUrl(optionValueName, optionTypeId)
        ?.transparent_config_image || optionsImage,
    fabricImageFallback: optionsImage,
    fabricId: optionValueId,
    fabricName: optionValueName,
    fabricAdditionalCost:
      optionValueCost || optionValueForImgFallback?.additional_cost,
    fabricUrlParams: `?${finalProductOption.name.toLowerCase()}=${
      urlString.endsWith('-') ? urlString.slice(0, -1) : urlString
    }`,
    fabricSku: optionSku,
    fabricColor: optionColor,
  };
}

function getSwatchDataForOptions({
  upholsteryOptions,
  optionTypeId,
  finalProductOption,
}) {
  if (!upholsteryOptions?.length) {
    return [];
  }

  return upholsteryOptions.map(option =>
    getFabricSwatchData({
      optionValueName: option.value,
      optionValueId: option.id,
      optionValueCost: option.additional_cost,
      optionSku: option.sku,
      optionTypeId,
      finalProductOption,
      optionColor: option.color,
    })
  );
}

export function getTopFabricSwatchesData({
  product,
  options = [],
  showVariation,
  forMaterial = '',
  forMaterialType = '',
  displaySwatchCount = 4,
}) {
  try {
    let fabricSwatchData = null;
    let upholsteryOptions = null;
    let swatchesCount = 0;
    if (!options.length) {
      return [];
    }
    const optionTypeId = getProductPrimaryOptionId(options, product);
    if (!optionTypeId) {
      return [];
    }
    const { efg_flag, is_external } = product;
    const isEFG = !!efg_flag && !is_external;
    const finalProductOption = find(options, { id: optionTypeId });
    swatchesCount = finalProductOption.values.length;
    let showVariantUpholsteryOption = null;
    let forMaterialOption = null;
    if (
      showVariation &&
      [
        FABRIC_PRODUCT_OPTION_ID,
        LEATHER_PRODUCT_OPTION_ID,
        WOOD_PRODUCT_OPTION_ID,
      ].includes(optionTypeId)
    ) {
      showVariantUpholsteryOption = getFabricOptionValueByPartSku({
        options,
        showVariation,
        primaryOptionTypeId: optionTypeId,
      });
    }

    if (forMaterial && forMaterialType) {
      const finalMaterialVals = convertOptionParamFormat(
        forMaterialType,
        forMaterial,
        'original'
      );

      forMaterialOption = getOptionValueByNameAndOptionType({
        optionType: finalMaterialVals.finalOptionName,
        optionValueName: finalMaterialVals.finalOptionValue,
        options,
      });
    }

    if (optionTypeId === FABRIC_PRODUCT_OPTION_ID) {
      let popularValues = getPopularValuesFromOptions({
        productOption: finalProductOption,
        isEFG,
      });

      if (!popularValues.length) {
        popularValues = getFirstNOptionValues(finalProductOption, 4);
      }

      let defaultOptionValue = null;
      // Add default value in final options
      if (product.default_option_value && product.default_option_image) {
        defaultOptionValue = `-${
          product.default_option_value.split('-').filter(v => !!v)[0]
        }`;
        defaultOptionValue = finalProductOption.values.filter(
          optionVal => optionVal.sku === defaultOptionValue
        );
      }
      upholsteryOptions = popularValues;
      if (
        defaultOptionValue?.length &&
        !find(upholsteryOptions, { id: defaultOptionValue.id })
      ) {
        upholsteryOptions.unshift(defaultOptionValue[0]);
      }
    } else if (finalProductOption) {
      upholsteryOptions = finalProductOption.values.filter(
        optVal => (!isEFG && !!optVal?.is_active) || isEFG
      );
    }
    if (showVariantUpholsteryOption?.id && upholsteryOptions?.length) {
      // Check if this option is already in swatches
      const variantOptionIndex = upholsteryOptions.findIndex(
        opt => opt.id === showVariantUpholsteryOption.id
      );
      if (variantOptionIndex > -1) {
        upholsteryOptions.splice(variantOptionIndex, 1);
      }
      upholsteryOptions.unshift(showVariantUpholsteryOption);
    }

    if (forMaterialOption) {
      // Check if this option is already in swatches
      const variantOptionIndex = upholsteryOptions.findIndex(
        opt => opt.id === forMaterialOption.id
      );
      if (variantOptionIndex > -1) {
        upholsteryOptions.splice(variantOptionIndex, 1);
      }
      upholsteryOptions.unshift(forMaterialOption);
    }

    if (!upholsteryOptions?.length) return null;

    if (upholsteryOptions.length > displaySwatchCount) {
      upholsteryOptions.length = displaySwatchCount;
    }

    fabricSwatchData = getSwatchDataForOptions({
      upholsteryOptions,
      optionTypeId,
      finalProductOption,
    });

    return [fabricSwatchData, swatchesCount];
  } catch (e) {
    logError(e, 'Error while populating popular swatches data');
    return null;
  }
}

export const getQuickShipSwatchDataFromOptions = ({
  stockOptions,
  product,
}) => {
  const optionTypeId = getProductPrimaryOptionId(stockOptions, product);
  if (!optionTypeId) {
    return [];
  }
  const finalProductOption = find(stockOptions, { id: optionTypeId });

  return getSwatchDataForOptions({
    upholsteryOptions: finalProductOption.values,
    optionTypeId,
    finalProductOption,
  });
};

export function getFabricColorOptions(options, colorOptions) {
  if (!options || !colorOptions) {
    return null;
  }
  const allFabricOptions = options.find(
    option => option.id === FABRIC_PRODUCT_OPTION_ID
  );
  const allFabricOptionIds = allFabricOptions.values.map(option => option.id);
  return colorOptions.filter(option =>
    allFabricOptionIds.includes(option.option_value_id)
  );
}

function getNewSwatchesAndSwatchIndex({
  newSwatches = [],
  optionTypeId,
  finalProductOption,
  filteredColors = [],
  position,
}) {
  let finalFabricSwatchData = [];
  let newSwatchIndex = 0;
  if (!newSwatches?.length) {
    return [[], 0];
  }
  finalFabricSwatchData = newSwatches
    .map(swatchOption => {
      const fabricSwatchObj = getFabricSwatchData({
        optionValueName: swatchOption.option_value,
        optionValueId: swatchOption.option_value_id,
        optionSku: swatchOption.option_sku,
        optionTypeId,
        finalProductOption,
      });
      return fabricSwatchObj;
    })
    .filter(option => !!option);

  if (!finalFabricSwatchData) {
    finalFabricSwatchData = [];
  }

  if (optionTypeId === FABRIC_PRODUCT_OPTION_ID && filteredColors.length) {
    let finalLength = filteredColors.length > 4 ? 4 : filteredColors.length;
    if (finalFabricSwatchData.length < filteredColors.length) {
      finalLength = finalFabricSwatchData.length;
    }
    let mod = position % finalLength;
    if (!mod) {
      mod = finalLength;
    }
    if (finalFabricSwatchData.length > 2 && filteredColors.length === 2) {
      mod = position % finalLength === 0 ? mod + 1 : mod;
    }
    newSwatchIndex = mod - 1;
    if (finalFabricSwatchData.length === 1) {
      newSwatchIndex = 0;
    }
  }

  const newFabricSwatchData =
    finalFabricSwatchData.length > 4
      ? finalFabricSwatchData.slice(0, 4)
      : finalFabricSwatchData;
  newSwatchIndex = newSwatchIndex < 0 ? 0 : newSwatchIndex;
  return [newFabricSwatchData, newSwatchIndex];
}

export const getFabricSwatchesForColors = ({
  sortedAllOptionsValues,
  options,
  product,
  filteredColors,
  position,
}) => {
  let newSwatches = [];

  const optionTypeId = getProductPrimaryOptionId(options, product);
  const finalProductOption = find(options, { id: optionTypeId });
  if (optionTypeId === VENDOR_LEATHER_PRODUCT_OPTION_ID) {
    return [[], 0];
  }
  filteredColors?.forEach(color => {
    if (sortedAllOptionsValues?.[optionTypeId]?.[color]?.length) {
      if (optionTypeId === FABRIC_PRODUCT_OPTION_ID) {
        const productColorOptions = getFabricColorOptions(
          options,
          sortedAllOptionsValues[optionTypeId][color]
        );
        if (!productColorOptions?.length) {
          return;
        }
        if (filteredColors.length === 1) {
          newSwatches = productColorOptions.slice(0, 4);
        } else if (filteredColors.length === 2) {
          if (!newSwatches.length) {
            newSwatches = newSwatches.concat(productColorOptions.slice(0, 2));
          } else {
            newSwatches.unshift(productColorOptions[0]);
            if (productColorOptions.length > 1) {
              newSwatches.unshift(productColorOptions[1]);
            }
          }
        } else if (filteredColors.length > 2) {
          if (newSwatches.length) {
            newSwatches.unshift(productColorOptions[0]);
            // newSwatches = newSwatches.slice(0, 4);
          } else {
            newSwatches.push(productColorOptions[0]);
          }
        }
      } else {
        newSwatches = sortedAllOptionsValues[optionTypeId][color];
      }
    }
  });
  return getNewSwatchesAndSwatchIndex({
    newSwatches,
    optionTypeId,
    finalProductOption,
    filteredColors,
    position,
  });
};

export function getFilteredVelvetSwatches({
  filteredColors = [],
  allVelvetMaterialOptions = [],
  product,
  options,
  position,
}) {
  let newSwatches = [];
  const optionTypeId = getProductPrimaryOptionId(options, product);
  const finalProductOption = find(options, { id: optionTypeId });
  if (
    optionTypeId === VENDOR_LEATHER_PRODUCT_OPTION_ID ||
    optionTypeId !== FABRIC_PRODUCT_OPTION_ID
  ) {
    return [[], 0];
  }
  const availableProductOptionValueIds = finalProductOption.values.map(
    optVal => optVal.id
  );
  if (filteredColors.length) {
    for (let i = 0; i < filteredColors.length; i++) {
      const color = filteredColors[i];
      if (allVelvetMaterialOptions[FABRIC_PRODUCT_OPTION_ID][color]) {
        newSwatches = newSwatches.concat(
          allVelvetMaterialOptions.Fabric[color].slice(0, 4)
        );
      }
    }
  } else {
    newSwatches = Object.values(
      allVelvetMaterialOptions[FABRIC_PRODUCT_OPTION_ID]
    ).flat();
  }

  newSwatches = newSwatches
    .filter(swatchVal =>
      availableProductOptionValueIds.includes(swatchVal.option_value_id)
    )
    .sort((a, b) => a.sort_order - b.sort_order);

  return getNewSwatchesAndSwatchIndex({
    newSwatches,
    optionTypeId,
    finalProductOption,
    filteredColors,
    position,
  });
}

export function sortOptionsByColor(optionsData) {
  if (!ALLOPTIONS_CACHE) {
    if (!optionsData) {
      return null;
    }
    const finalOptionsObject = {};
    optionsData.forEach(option => {
      if (option.option_id && option.option_color) {
        const optColor = option.option_color.toLowerCase();
        if (!finalOptionsObject[option.option_id])
          finalOptionsObject[option.option_id] = {};
        if (!finalOptionsObject[option.option_id][optColor])
          finalOptionsObject[option.option_id][optColor] = [];
        finalOptionsObject[option.option_id][optColor].push(option);
      }
    });
    ALLOPTIONS_CACHE = finalOptionsObject;
  }
  return ALLOPTIONS_CACHE;
}

export function setVelvetMaterials({
  forMaterialGroupOptionIds,
  sortedAllOptionsValues,
}) {
  const tempVelvetGroupOptionsData = {};
  Object.keys(forMaterialGroupOptionIds).forEach(key => {
    if (key) {
      const keyL = key.toLowerCase();
      tempVelvetGroupOptionsData[keyL] = sortedAllOptionsValues[
        FABRIC_PRODUCT_OPTION_ID
      ][keyL].filter(x =>
        forMaterialGroupOptionIds[key].includes(x.option_value_id)
      );
    }
  });
  return { [FABRIC_PRODUCT_OPTION_ID]: tempVelvetGroupOptionsData };
}

export function shouldCurrentSwatchSetUpdate({ currentSet, newSet }) {
  if (!!currentSet?.length && !newSet) {
    return false;
  }
  if (
    (!currentSet && !!newSet?.length) ||
    currentSet.length !== newSet.length
  ) {
    return true;
  }
  let setShouldUpdate = false;
  for (let i = 0; i < currentSet.length; i++) {
    if (currentSet[i].fabricId !== newSet[i].fabricId) {
      setShouldUpdate = true;
      break;
    }
  }
  return setShouldUpdate;
}
