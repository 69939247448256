import { makeVar } from '@apollo/client';
import isClient from 'commons/isClient';

const defaultProductVar = {
  swatches: [],
  swatchIndex: 0,
  swatchHovered: false,
};

function ProductCardVarFactory() {
  const productCardVarSet = {};

  const getProductCardVar = id => {
    if (!id || !isClient()) {
      return null;
    }

    if (typeof productCardVarSet[id] !== 'function') {
      productCardVarSet[id] = makeVar({
        id,
        ...defaultProductVar,
      });
    }

    return productCardVarSet[id];
  };

  return {
    getProductCardVar,
  };
}

export default ProductCardVarFactory();
