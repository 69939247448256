import { COMPONENT_CALLER_TYPE } from 'commons/constants';
import useProductCardClearanceData from './useProductCardClearanceData';
import usePDPClearanceData from './usePDPClearanceData';

const useClearanceData = (id = null, slug = null, hookOptions = {}) => {
  const { callerType } = hookOptions;

  const isPDP = callerType === COMPONENT_CALLER_TYPE.PDP;

  const clearanceHookDataPDP = usePDPClearanceData({
    skip: !isPDP,
    ...hookOptions,
  });

  const clearanceHookDataProductCard = useProductCardClearanceData(id, slug, {
    skip: isPDP,
    ...hookOptions,
  });

  const clearanceHookData =
    callerType === COMPONENT_CALLER_TYPE.PDP
      ? clearanceHookDataPDP
      : clearanceHookDataProductCard;

  return clearanceHookData;
};

export default useClearanceData;
