import { getProductPrimaryOption } from 'commons/productUtils';

export const getForMaterialSKU = ({
  options,
  forMaterial = '',
  forMaterialType = '',
}) => {
  if (!options?.length || !forMaterial || !forMaterialType) {
    return '';
  }
  const trimmedForMaterial = forMaterial.trim();
  const trimmedForMaterialType = forMaterialType.trim();
  const materialOption = options.find(
    optVal => optVal.name === trimmedForMaterialType
  );
  return (
    materialOption?.sku ||
    (materialOption?.values?.length > 0
      ? materialOption.values.find(val => val.value === trimmedForMaterial)?.sku
      : '') ||
    ''
  );
};

const getForMaterialForOptions = ({
  options,
  forMaterial = '',
  forMaterialType = '',
}) => {
  if (!options?.length || !forMaterial || !forMaterialType) {
    return null;
  }
  const materialOption = options.find(
    optVal => optVal.name === forMaterialType.trim()
  );
  let optionValue = null;
  if (!materialOption) {
    return null;
  }
  for (let i = 0; i < materialOption.values.length; i++) {
    if (materialOption.values[i].value === forMaterial.trim()) {
      optionValue = materialOption.values[i];
      break;
    }
  }
  return optionValue;
};

export const getForMaterialAdditionalPrice = ({
  options,
  forMaterial = '',
  forMaterialType = '',
}) => {
  const optionValue = getForMaterialForOptions({
    options,
    forMaterial,
    forMaterialType,
  });
  return optionValue?.additional_cost ?? 0;
};

export const getForMaterialExcludeFromSale = ({
  options,
  forMaterial = '',
  forMaterialType = '',
}) => {
  const optionValue = getForMaterialForOptions({
    options,
    forMaterial,
    forMaterialType,
  });
  return !!optionValue?.exclude_from_sale;
};

export const getAdditionalOptionCost = (option, optionValueId) => {
  if (option && option.values) {
    const optionValue = option.values.find(o => o.id === optionValueId);
    if (optionValue && optionValue.additional_cost) {
      return optionValue.additional_cost;
    }
  }
  return 0;
};

export const getOptionAdditionalPrice = (
  options,
  stockOptions,
  selection,
  includeUpholsteryAddlCost = true,
  upholsteryOptionId
) => {
  let price = 0;
  if (options && selection) {
    Object.keys(selection).forEach(id => {
      if (!includeUpholsteryAddlCost && +id === upholsteryOptionId) {
        return;
      }
      const optionValueId = selection[id];
      const optionId = parseInt(id, 10);
      const option = options.find(o => o.id === optionId);
      let optionAdditionalCost = getAdditionalOptionCost(option, optionValueId);
      if (!optionAdditionalCost && stockOptions) {
        optionAdditionalCost = getAdditionalOptionCost(
          stockOptions.find(o => o.id === optionId),
          optionValueId
        );
      }
      price += optionAdditionalCost;
    });
  }
  return price;
};

export const getFirstStockOptionSKU = (stockOptions = []) => {
  if (!stockOptions?.length) {
    return '';
  }
  const primaryOption = getProductPrimaryOption(stockOptions);
  if (!primaryOption) {
    return '';
  }

  return primaryOption.values?.[0]?.sku || '';
};
const getFirstStockOption = (stockOptions = []) => {
  if (!stockOptions?.length) {
    return null;
  }
  const primaryOption = getProductPrimaryOption(stockOptions);
  if (!primaryOption) {
    return null;
  }
  return primaryOption.values?.[0] || null;
};
export const getFirstStockOptionPrice = (stockOptions = []) => {
  const stockOption = getFirstStockOption(stockOptions);
  return stockOption?.additional_cost || 0;
};
export const getFirstStockOptionExcludeFromSale = (stockOptions = []) => {
  const stockOption = getFirstStockOption(stockOptions);
  return stockOption?.exclude_from_sale || false;
};
