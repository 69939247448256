/**
 *  Converts given option name and value to query param format and back
 *  Eg = Fabric <-> fabric  and Taylor Felt Grey <-> taylor-felt-grey
 *  @param {optionName} String
 *  @param {optionValueName} String
 *  @param {format} String One of 'qs' or 'original'
 */
const convertOptionParamFormat = (
  optionName,
  optionValueName,
  format = 'qs'
) => {
  let finalOptionName = '';
  let finalOptionValue = '';
  switch (format) {
    case 'original':
      finalOptionName = optionName
        .split('-')
        .filter(val => !!val)
        .map(v => `${v[0].toUpperCase()}${v.slice(1)}`)
        .join(' ');
      finalOptionValue = optionValueName
        .split('-')
        .filter(val => !!val)
        .map(v => `${v[0].toUpperCase()}${v.slice(1)}`)
        .join(' ');
      break;
    case 'qs':
    default:
      finalOptionName = optionName
        .split(' ')
        .filter(val => !!val)
        .map(v => v.toLowerCase())
        .join('-');
      finalOptionValue = optionValueName
        .split(' ')
        .filter(val => !!val)
        .map(v => v.toLowerCase())
        .join('-');
      break;
  }
  return {
    finalOptionName,
    finalOptionValue,
  };
};

export default convertOptionParamFormat;
